import * as React from 'react'
import { type HeadFC, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

const HistoryPage = () => {
  return (
    <Layout>
      <section className="flex min-h-screen flex-col px-4 py-8">
        <div className="sm:container sm:mx-auto">
          <h1 className="mb-8 text-3xl font-bold text-gray-800 sm:text-5xl">
            Nuestra Historia
          </h1>
          <p className="mb-12 text-xl text-gray-500 sm:text-2xl">
            Cómo llegó el Evangelio a la ciudad de Madrid y al barrio de
            Chamberí.
          </p>
        </div>
        <div className="mx-auto max-w-2xl">
          <section className="text-slate-800 sm:text-xl">
            <p className="mb-8">
              El primer misionero del ámbito de las Asambleas de Hermanos que
              llegó a España fue <strong>Robert C. Chapman</strong> (1803-1902)
              en el año 1834. Era un abogado londinense, políglota y siervo del
              Señor en Barnstaple (Inglaterra), identificado gozosamente con los
              principios del movimiento de los &quot;Hermanos&quot;. En
              diciembre de 1864, Chapman dio una reseña oral de lo que vio y
              experimentó por tierras de Castilla y Aragón:
            </p>
            <p className="ml-10 mb-8">
              Allá por el año 1824, tan pronto como fui liberado de una vida
              egoísta y pecaminosa por el Evangelio de Cristo, el estado
              espiritual de la Península Ibérica empezó a pesar sobre mi
              corazón. Vosotros sabéis que por siglos han estado enteramente muy
              subyugados bajo el poder de la iglesia romana, y en ellos el
              nombre de Cristo ha servido de capa bajo la cual se ha practicado
              la idolatría más abominable que jamás haya provocado a Dios.
            </p>
            <p className="mb-8">
              Hizo tres viajes misioneros a España. Su primer recorrido lo hizo
              individualmente en el año 1834, en plena primera guerra Carlista;
              anduvo desde el mar Cantábrico hasta Madrid, pasando probablemente
              por Santander, Burgos y Aranda del Duero. Tras una breve estancia
              en la Villa y Corte, el incansable Chapman emprendió el camino
              inverso al que recorriera <strong>George H. Borrow</strong>{' '}
              (1803-1881) dos años más tarde: de Madrid a Lisboa, pasando por
              Talavera de la Reina, el puerto de Miravete, Mérida y Badajoz. En
              Portugal, se embarcó rumbo a Inglaterra.
            </p>
            <div className="flex flex-col sm:flex-row-reverse">
              <div className="mb-8 text-center sm:flex-1 sm:pl-4">
                <StaticImage
                  alt="Dibujo realista de Robert C. Chapman."
                  src="../images/robert-c-chapman.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Dibujo realista de Robert C. Chapman.
                </figcaption>
              </div>
              <div className="sm:flex-1">
                <p className="mb-8">
                  Cinco años más tarde, en el año 1839, Chapman volvió
                  acompañado por los discipulos suyos: Pick y Handcock.
                  Estuvieron en Vigo, pasaron por Asturias y Santander y se
                  detuvieron en Bilbao y San Sebastián, donde pudieron apreciar
                  los daños de esa primera Guerra Civil. Y también visitaron a
                  conocidos en Madrid. Yendo de ciudad en ciudad, iban
                  distribuyendo Evangelios y Nuevos Testamentos y hablando
                  individualmente a bastantes españoles del Cristo de las
                  Escrituras, de su inefable amor, su inagotable perdón y de la
                  perfección de su obra redentora, que no admite añadiduras.
                  Sigue diciendo Chapman:
                </p>
              </div>
            </div>
            <p className="ml-10 mb-8">
              El propósito que tenía era el de dar a conocer el Evangelio del
              modo que lo hizo el Señor Jesús a la mujer samaritana, y lo hizo
              el Apóstol Pablo en Filipos, cuando habló a las mujeres que se
              reunían a orillas del río. Aquella conversación del Apóstol en
              Filipos fue el principio del Evangelio en Europa.
            </p>
            <p className="mb-8">
              Así, llevaron algunas almas al conocimiento salvador de Cristo y
              establecieron diversos contactos con personas que anhelaban un
              cristianismo más puro y auténtico; pero más no pudieron hacer,
              debido a la peculiar situación político-religiosa de la época.
            </p>
            <p className="mb-8">
              En efecto, por aquel entonces la monarquía española contaba con
              quince millones de súbditos, de los cuales 270.000 pertenecían al
              clero. Éstos no sólo poseían amplias riquezas materiales, sino que
              dominaban sobre toda la vida nacional. Así, el sistema romanista
              controlaba la existencia de cada español e incidía sobre su
              desarrollo cultural, social y hasta económico. Heredadas de la
              Reconquista medieval, las estructuras de la sociedad permanecían
              profundamente desiguales, injustas y muy poco cristianas: el 6% de
              la misma poseía las ⅔ partes de las riquezas nacionales. Más del
              70% de la nación vivía en poblaciones de menos de 10.000
              habitantes y dependía esencialmente de la agricultura y de sus
              precarias cosechas. Más del 80% de los españoles eran analfabetos
              y sólo en las grandes ciudades se encontraba un reducido núcleo de
              gentes abiertas y liberales.
            </p>
            <p className="mb-8">
              En cuanto al estado moral de la nación, así lo calificaba
              Victoriano López y Gonzalo, Obispo de Cartagena:
            </p>
            <p className="ml-10 mb-8">
              Impiedad, profanación de lo sagrado, usura reprobada, codicia sin
              límites, desordenado afán de riquezas, […] costumbres estragadas y
              opresión de los desvalidos […].
            </p>
            <p className="mb-8">
              Debido a esa situación, Chapman tardó 24 años en volver a España,
              pero oraba diariamente para que el Señor le abriera una puerta y
              hablaba por doquier de las necesidades espirituales de España.
              Finalmente, en 1863, el incansable siervo de Dios fue oído. Vino
              por tercera vez a España, acompañado por dos nuevos misioneros:{' '}
              <strong>William Gould</strong> (fallecido en 1870) y{' '}
              <strong>George Lawrence Davis</strong> (1831-1894). Prosigue
              Chapman:
            </p>
            <p className="ml-10 mb-8">
              Al emprender el viaje se nos pidió que lleváramos socorro material
              (y espiritual) a ciertos hermanos españoles que estaban en Burdeos
              (Francia), los cuales, poco antes, habían sido sacados de la
              cárcel y expulsados de España por causa del Evangelio.
            </p>
            <p className="mb-8">
              Así llevaron ropas y palabras de aliento a Marín, González y
              Flores, compañeros de <strong>Manuel Matamoros</strong>{' '}
              (1834-1866) en la cárcel de Málaga. A continuación pasaron de
              Burdeos a Lyón:
            </p>
            <p className="ml-10 mb-8">
              Vimos a nuestro hermano Matamoros, un verdadero siervo de Cristo.
              Se alegró mucho de vernos y tanto más al saber nuestro propósito
              de visitar su país.
            </p>
            <p className="mb-8">
              Chapman y sus discípulos estuvieron primero tres meses en Bilbao,
              publicando el Mensaje de salvación y distribuyendo cuidadosamente
              las Sagradas Escrituras, escasas y prácticamente desconocidas en
              toda la Península. A continuación, Gould estuvo testificando
              individualmente en Orduña (Vizcaya) hasta que fue arrestado y
              acusado ante un tribunal por haber regalado una Biblia (la versión
              católica de Scío) a un seminarista vasco.
            </p>
            <div className="flex flex-col sm:flex-row">
              <div className="mb-8 text-center sm:flex-1 sm:pr-4">
                <StaticImage
                  alt="Retrato de William Greene."
                  src="../images/william-greene.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-center text-sm text-gray-500">
                  Retrato de William Greene.
                </figcaption>
              </div>
              <div className="sm:flex-1">
                <p className="mb-8">
                  Sobreseído el juicio, los tres pioneros del Señor fueron de
                  ciudad en ciudad, sintiéndose deudores a castellanos y
                  aragoneses, a vascos y andaluces, a gallegos, levantinos y
                  baleares y siguieron hablando de Cristo en Barcelona, Madrid,
                  Valencia, Lisboa, Palma de Mallorca, Alicante, etc. En
                  Mallorca, Chapman visitó al ingeniero inglés y ferviente
                  testigo de Cristo: <strong>William Greene</strong>{' '}
                  (1819-1915), de quién volveremos a hablar. Fruto de su
                  testimonio sería la formación de una congregación en Palma,
                  anterior a la obra metodista, que en 1875 contaba ya con una
                  asamblea de Hermanos de cuarenta miembros.
                </p>
              </div>
            </div>
            <p className="mb-8">
              Chapman volvió a Inglaterra al cabo de 15 meses, dejando a sus dos
              discípulos en España. El 12 de junio de 1902, Chapman partió para
              estar con Cristo a la edad de 99 años. Según un testigo, durante
              los breves días de su enfermedad disfrutó de una paz inefable:
            </p>
            <p className="ml-10 mb-8">
              Con aquella voz que tan a menudo y con tanta lucidez había
              explicado las Escrituras a centenares, citando pasajes bíblicos
              casi incesantemente. Su corazón estaba lleno de las Escrituras.
              Finalmente, poniendo una mano sobre el pecho, dijo: «Aquella paz
              (Filipenses 4.7) la tengo yo aquí.»
            </p>
            <p className="mb-8">
              En el verano de 1865, al llegar Gould y Lawrence a Vitoria, fueron
              denunciados a las autoridades locales y —a duras penas— lograron
              ponerse a salvo huyendo a Francia. Fueron condenados en rebeldía a
              nueve años de cárcel, sentencia semejante a la que fueron
              condenados Manuel Matamoros y sus compañeros, testigos de Cristo
              en Granada, Málaga y ciudades andaluzas. Contará más tarde George
              Lawrence:
            </p>
            <p className="ml-10 mb-8">
              Nuestros dos primeros años de servicio en España los empleamos en
              visitar muchos lugares y en introducir centenares de ejemplares de
              la Palabra de Dios. Estos, no sólo fueron entregados a personas
              interesadas, sino que fueron distribuidos también en jardines
              públicos. Así pudimos conocer los sentimientos del pueblo español,
              aumentando nuestra convicción de que no estaba lejos el día cuando
              todas las barreras caerían y la Palabra de Dios tendría libre
              curso […].
            </p>
            <p className="mb-8">
              Son palabras proféticas que se cumplieron a los tres años, cuando
              en medio del hambre y del profundo malestar político, estalló la
              Revolución de 1868. Isabel II tuvo que abdicar y los generales
              Prim y Serrano formaron un gobierno liberal, iniciando el llamado
              “Sexenio Democrático” (1868–1874). Éste empezó en un ambiente de
              exaltada libertad y acabó en la más completa anarquía.
            </p>
            <p className="mb-8">
              Para la causa evangélica, ese breve y agitado periodo permitió la
              instauración de la libertad religiosa, por vez primera en toda la
              historia de España. Así pues, los dos discípulos de Chapman
              volvieron a España tras el derrocamiento de Isabel II. Gould se
              estableció en Chamberí, barrio “extramuros” de la Villa y Corte, y
              George Lawrence acabó por fijarse en Barcelona para proseguir allí
              los trabajos de evangelización iniciados anteriormente. En
              Barcelona y las aldeas de Cataluña, Lawrence desarrollaría su
              trabajo más conocido con el carro blindado —o coche bíblico— como
              librería ambulante. Éste contaba con ventanas laterales, y cuando
              se abrían formaban unos escaparates y mostrador donde podían verse
              y comprarse biblias, Nuevos Testamentos y materiales
              evangelísticos.
            </p>
            <div className="mb-8 text-center">
              <figure>
                <StaticImage
                  alt="El coche bíblico de George Lawrence."
                  src="../images/coche-biblico-george-lawrence.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  El coche bíblico de George Lawrence.
                </figcaption>
              </figure>
            </div>
            <p className="mb-8">Sigue contando Lawrence:</p>
            <p className="ml-10 mb-8">
              Aprovechamos la puerta abierta de par en par, y volvimos aquel
              mismo año de 1868. Hallamos a la gente gozosa y llena de alegría y
              el único exceso que (al parecer) se cometía, era el afán de
              comprar cualquier libro o periódico en el que estuviera impresa la
              palabra “LIBERTAD” […].
            </p>
            <p className="mb-8">Añade Gould:</p>
            <p className="ml-10 mb-8">
              Tan pronto como pasamos la frontera notamos el profundo cambio
              operado en la gente […]. Ahora, al anunciar la salvación en Cristo
              y al distribuir porciones del Evangelio, todos deseaban oír con la
              mayor atención. Cuando llegué a Madrid, supe que el hermano
              Lawrence había logrado alquilar, en una calle céntrica, una tienda
              con amplio escaparate donde podía distribuir y vender los
              Evangelios a precios ínfimos […].
            </p>
            <p className="mb-8">
              Se supo más tarde que era una sombrerería, sita en la Carrera de
              San Jerónimo, frente al Parlamento. Allí, un fanático disparó su
              pistola contra Lawrence, pero el Señor protegió a su siervo. En la
              proclamación de la Regencia, la comitiva oficial pasó a escasos
              metros del puesto evangélico y oyó proclamar —con voz clara y
              potente— las Buenas Noticias del auténtico Cristo; pudiendo leer
              los grandes textos bíblicos expuestos: «Porque de tal manera amó
              Dios al mundo, que ha dado a su Hijo unigénito […]» (Juan 3.16).
              «[…] arrepentíos, y creed en el evangelio.» (Marcos 1.15). «Porque
              hay un solo Dios, y un solo mediador entre Dios y los hombres,
              Jesucristo hombre.» (1 Timoteo 2.5).
            </p>
            <p className="mb-8">
              Así, tanto la Revolución del 68, como la monarquía democrática de
              Amadeo I de España y la posterior Primera República Española,
              permitieron el inicio de la Obra evangélica pública en toda la
              península ibérica; y pronto se formaron iglesias en Mahón,
              Sevilla, Cádiz, Madrid, Barcelona, Cartagena y Córdoba.
            </p>
            <h2 className="mb-10 text-2xl font-bold text-gray-800 md:text-4xl">
              Madrid, Villa y Corte
            </h2>
            <p className="mb-8">
              Desde un principio, Gould y Lawrence escogieron Madrid como centro
              de sus actividades misioneras. En este período inicial de
              libertad, la primera y más urgente tarea era la de dar a conocer
              la Palabra de Dios. Luego, hubo que abrir escuelas primarias para
              que este pueblo llano aprendiera a leer, ya que (como vimos) más
              del 80% del mismo era analfabeto.
            </p>
            <p className="mb-8">
              En cuanto a las necesidades pedagógicas de la época, Isabel
              Valcarcel las califica como sigue:
            </p>
            <p className="ml-10 mb-8">
              Los protestantes trajeron a España la pedagogía moderna de sus
              respectivos países, escandalizándose del sistema cuasi medieval de
              nuestras escuelas. En un país con mayoría de población analfabeta,
              estas escuelas protestantes no sólo contribuyeron a paliar el
              “déficit de plazas escolares” —secularmente atrasado— sino que
              brillaron con luz propia. (Véase: “Protestantes en España”, por
              Isabel Valcárcel; en “Historia 16”, nº 111; págs. 106-107).
            </p>
            <p className="mb-8">
              Con el decidido apoyo del colportor José Ríos y del músico,
              himnólogo y potente predicador <strong>Mateo Cosidó</strong>{' '}
              (1825–1870), Gould y Lawrence no desaprovecharon ocasión alguna de
              testificar. Durante una gran manifestación republicana,
              aprovechando la concurrencia, en hora y media pudieron vender
              (siempre sin el menor afán de lucro) más de tres mil evangelios,
              2.500 epístolas paulinas, más unas decenas de Biblias y Nuevos
              Testamentos; repartiendo además unos 7.000 folletos de
              evangelización. Estos se titulaban, por ejemplo: “Carta a un amigo
              sobre la Redención”; “Reflexiones sobre la Eternidad”; “Un
              Salvador para ti”; etc.
            </p>
            <p className="mb-8">
              Y llevaron a cabo otras distribuciones masivas con motivo de la
              fiesta de San Isidro, en Madrid; luego aprovechando las ferias de
              Toledo, Alcalá de Henares, Medina del Campo, Salamanca, Valladolid
              y Sevilla. Siempre con la eficaz ayuda de J. Ríos y M. Cosidó,
              recorrieron asimismo Aragón, la Rioja y Navarra, repartiendo miles
              de evangelios y folletos.
            </p>
            <p className="mb-8">
              Luego, por el conocimiento acumulado y las experiencias vividas
              durante esos años en noviembre de 1869, George Lawrence se
              trasladó a Barcelona. En cuanto a Gould, siempre secundado por
              Ríos y Cosidó, decidió abrir dos locales en la Villa y Corte. Uno,
              ubicado en la calle Amaniel, servía para las escuelas durante la
              semana y de sala de evangelización los domingos. El otro local o
              “Sala Evangélica de la Paz”, con capacidad para 400 oyentes, se
              inauguró el 6 de enero de 1870, poco después de que William Greene
              llegase de Mallorca. Viendo las necesidades espirituales de la
              capital, Greene dejó su carrera de ingeniero para consagrarse
              plenamente a la Obra del Señor. En la primavera del mismo año, se
              añadió al primitivo grupo de “hermanos” madrileños el ex-sacerdote
              Tristán Medina. Su poderoso verbo (que contrastaba los errores de
              Roma con el sencillo Evangelio de Cristo), atraía —varias veces a
              la semana— a centenares de oyentes; unos con auténtica inquietud
              espiritual, otros interesados tan sólo por la polémica.
            </p>
            <p className="mb-8">
              La obra era más que prometedora, mayormente al alquilar Greene (en
              enero de 1871) un tercer local, sito en la calle Calatrava 25, que
              había servido de salón de baile. Pero, antes de finalizar 1870,
              Gould y luego Cosidó fueron llamados a la presencia del Señor y,
              18 meses más tarde, Greene se marchó de Madrid. Antes, entregó
              tanto la “Sala Evangélica de la Paz” como el local de la calle
              Calatrava a la Comisión Central de la “Unión Evangélica Española”,
              obra dirigida por el misionero alemán{' '}
              <strong>Fritz Fliedner</strong> (1845-1901).
            </p>
            <h2 className="mb-10 text-2xl font-bold text-gray-800 md:text-4xl">
              Barrio de Chamberí
            </h2>
            <p className="mb-8">
              En su aspecto humano, la consolidación de la obra de las Asambleas
              en Madrid se debe a la estancia del evangelista{' '}
              <strong>Charles E. Faithful</strong> (fallecido en 1924), que
              llegó a Madrid en el otoño de 1872 y vivió en el barrio
              “extramuros“ de Chamberí. En aquél entonces, Chamberí era un
              pequeño barrio situado fuera de puertas; hoy es uno de los
              distritos más grandes de la capital y con una densidad de
              población bastante mayor que la de muchas ciudades de España.
              Todas esas manzanas de soberbios edificios situados desde el
              antiguo paseo de Areneros hacia el lado norte, y desde la calle de
              Galileo hasta la de Almagro, no eran más que desmontes donde iban
              a pacer los rebaños de cabras y donde los niños eran llevados por
              sus nodrizas para disfrutar del sol y para entregarse a sus
              juegos. Todas esas manzanas de casas de la acera de los pares de
              la calle de Carranza eran antaño nada menos que el quemadero de la
              Inquisición. Esa inmensa zona que forman las calles de Donoso
              Cortés, Galileo y adyacentes era un gran cementerio, llamado la
              Patriarcal del Norte. Las casas que allí había eran de lo más
              modesto y sus vecinos eran casi todos de la clase obrera.
            </p>
            <div className="mb-8 text-center">
              <figure>
                <StaticImage
                  alt="Plano parcelario Ibañez–Ibero del barrio de Chamberí en el año 1870."
                  src="../images/plano-chamberi-1870.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Plano parcelario Ibañez–Ibero del barrio de Chamberí en el año
                  1870.
                </figcaption>
              </figure>
            </div>
            <p className="mb-8">
              Cuando llegó Faithfull, el misionero Gould y el colaborador Cosidó
              han muerto, Green vive jubilado en Inglaterra, y las primeras
              capillas están en manos de la “Iglesia Cristiana Evangélica”
              (ICE). Asistido por el colportor Ríos, a principios de 1873
              Faithfull abrió un local evangélico en un piso primero en la calle
              de la Habana (ahora Eloy González 10) en el barrio de Chamberí.
              Poco después se abrió un colegio de niñas, al que siguió muy
              pronto la apertura de uno de niños.
            </p>
            <p className="mb-8">
              En 1873, dos misioneros jóvenes, alumnos de{' '}
              <strong>Charles H. Spurgeon</strong> (1834-1892), llegan a España
              traídos por Lawrence para apoyar la obra de Chamberí y aprender
              español. Eran <strong>Thomas Blamire</strong> (1848-1894) y su
              sobrino <strong>James P. Wigstone</strong> (fallecido en 1921),
              que pocos años más tarde iniciarían el trabajo pionero de las
              Asambleas en Galicia. Mientras tanto, ambos estuvieron
              evangelizando al aire libre en los pueblos limítrofes de Getafe,
              Valdemoro, Barajas, Chamartín y Fuencarral.
            </p>
            <p className="mb-8">
              El misionero <strong>Albert R. Fenn</strong> (1832–1896) y su
              esposa llegaron a Madrid en el año 1874 para dirigir la iglesia y
              las escuelas. De 1874 a 1895, el matrimonio Fenn sirvió de puntal
              a la obra, ya que —a los dos años de su llegada— Charles E.
              Faithfull se marchó, con otros tres misioneros, a Vitoria para
              iniciar un nuevo testimonio en las Vascongadas. Tras año y medio
              de continua persecución y duras luchas, Faithfull tuvo que
              renunciar a su empeño.
            </p>
            <div className="flex flex-col sm:flex-row">
              <div className="mb-8 text-center sm:flex-1 sm:pr-4">
                <StaticImage
                  alt="Dibujo realista de Albert R. Fenn."
                  src="../images/albert-r-fenn.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Dibujo realista de Albert R. Fenn.
                </figcaption>
              </div>
              <div className="sm:flex-1">
                <p className="mb-8">
                  En 1874, ya no era posible continuar más tiempo en la calle de
                  la Habana por resultar insuficiente. Entonces la iglesia y las
                  escuelas se trasladaron a una casa de la glorieta de Quevedo,
                  esquina a la calle de Arapiles, donde estuvieron por espacio
                  de veintiún años. El día 1 de noviembre de 1874 fue un día
                  grande, pues en tal fecha quedó constituida formalmente la
                  Iglesia Evangélica de Chamberí, y en esa fecha y por primera
                  vez sus miembros participaron de la Mesa del Señor.
                </p>
              </div>
            </div>
            <p className="mb-8">
              Gracias a una vigorosa predicación del Evangelio, refrendada por
              vidas transformadas, y una eficaz penetración social por medio de
              las escuelas (con casi medio millar de alumnos), el testimonio del
              Señor se fue afianzando en el barrio madrileño de Chamberí, a
              pesar de la creciente oposición clerical. El pueblo llano apreció
              también la labor humanitaria y desinteresada de los “hermanos”:
              clases de costura y reparto de sopas populares, clases nocturnas
              para adultos y estudios bíblicos, creación de una pequeña casa de
              huérfanos y de un incipiente hospital evangélico, atendido por el
              Dr. Fenn.
            </p>
            <p className="mb-8">
              Este ambiente evangelístico fue el que conoció el joven periodista
              canario que sería el gran escritor Benito Pérez Galdós. No sólo
              supo plasmar en sus novelas a los &quot;anabaptistas&quot; de
              Lavapiés y a los presbiterianos de las Peñuelas, sino que tanto en
              &quot;Fortunata y Jacinta&quot; como en &quot;Tristana&quot;
              menciona a los &quot;Hermanos&quot;. Al hablar de la maestra de
              inglés de Tristana, Galdós dice que era una misionera que «ejerció
              en la capilla evangélica» de Chamberí. También es posible que sea
              el matrimonio Fenn al que tiene en mente Galdós cuando escribe
              &quot;Torquemada en la hoguera&quot;, una &quot;gentil
              pareja&quot; protestante que llevan los nombres Horacio y Malvina.
            </p>
            <p className="mb-8">
              Desde Madrid, se siguió evangelizando en diversos pueblos: en la
              década de los ochenta Yuncos y Azaña (Toledo), donde había varios
              creyentes; y también Torrelaguna, más otros dos pueblos de la
              sierra madrileña. Por aquella década, la Asamblea de Hermanos de
              Chamberí contaba ya con un centenar de miembros comulgantes.
            </p>
            <p className="mb-8">
              En el barrio de Chamberí, en abril de 1895 (y tras 21 años de fiel
              y agotador ministerio) el matrimonio Fenn se vio obligado a
              regresar a Inglaterra por graves motivos de salud. Albert R. Fenn
              había tenido tres convulsiones leves en España, y antes del
              amanecer del 29 de julio de 1896, tuvo una severa mientras dormía.
              El 3 de agosto pasó a la presencia del Señor, estando consciente
              casi hasta el final. En la noticia de la muerte de Fenn que
              publicó en 1897 “The Missionary Review of the World”, una
              publicación periódica que informaba sobre las misiones cristianas
              protestantes en todo el mundo, lo describen así:
            </p>
            <p className="ml-10 mb-8">
              Con su vida santa e intachable, no menos que con su predicación,
              reprendía la abundante maldad incluso de los profanos guías
              religiosos, y con su honestidad y puntualidad en los negocios,
              presentaba un marcado contraste con el engaño y la dilación de los
              españoles. Era conocido en la pequeña capilla de Chamberí como “el
              santo” […]. A su muerte, desde la iglesia se envió una
              comunicación muy sencilla a su viuda. Estaba firmada por todos los
              que sabían escribir, y daba un fuerte testimonio de la maravillosa
              fuerza y ternura del vínculo que unía a este fiel pastor, padre
              amoroso y sabio consejero con su pueblo.
            </p>
            <h2 className="mb-10 text-2xl font-bold text-gray-800 md:text-4xl">
              La antigua capilla y las escuelas
            </h2>
            <p className="mb-8">
              Para sustituir a Albert R. Fenn llamaron al veterano Charles E.
              Faithfull, que estaba en aquél momento en Marsella (Francia). A
              pesar de los violentos ataques del “padre” Bocos y de las damas
              catequistas, Faithfull hizo honor a su apellido (Faithfull
              significa en inglés “lleno de fe”). El misionero supo dar nuevos
              bríos a la obra escolar y en el barrio las Pozas y, asimismo, a la
              “misión evangélica de Chamberí”. Su gran acierto fue la compra en
              1896 de un amplio solar en la calle de Trafalgar, donde se
              pudieron edificar las aulas escolares y la “capilla”. Con ello
              asentaba la obra en la geografía madrileña, evitando las continuas
              mudanzas debidas a presiones eclesiásticas.
            </p>
            <p className="mb-8">
              El periodista y escritor Luis Hernández Alfonso, publicó un
              reportaje el 20 de junio de 1929, en la Sección “Una información
              todas las noches” del diario “Heraldo de Madrid”, que decía:
            </p>
            <p className="ml-10 mb-8">
              La Iglesia Evangélica de Chamberí se halla situada en la hermosa
              calle de Trafalgar número 34, entre la plaza de Olavide y la
              calle, amplia y soleada, de Eloy Gonzalo, es decir, en pleno
              barrio chamberilero.
            </p>
            <p className="ml-10 mb-8">
              Tras de una modesta verja distínguense tres pabellones: el del
              centro, en cuya cancela se lee: “Iglesia Evangélica”, y los
              laterales, más elevados, y en los que sendos letreros rezan:
              “Colegios Evangélicos”. Es curiosa la circunstancia de que cada
              capilla protestante de Madrid esté dotada de sus correspondientes
              colegios. El interior del templo es humildísimo. No se ve en él ni
              el hermoso presbiterio de la iglesia del Redentor, ni el púlpito
              de la de Jesús, ni el órgano de la del Salvador. Las paredes,
              pintadas en color terroso, ostentan inscripciones bíblicas. Frente
              a la entrada, un estrado, y encima de él, en el testero, estas
              palabras escritas en grandes caracteres: “Nosotros predicamos a
              Cristo crucificado”.
            </p>
            <p className="ml-10 mb-8">
              En un ángulo se ve un pequeño armónium, con el cual se acompañan
              los coros de fieles a que tan aficionados son los evangélicos.
            </p>
            <p className="ml-10 mb-8">
              Delante del estrado, entre éste y las primeras filas de bancos,
              está el baptisterio a modo de cisterna, a cuyo fondo se desciende
              por unos escalones, y que ordinariamente se halla cubierto con una
              trampilla de madera.
            </p>
            <p className="ml-10 mb-8">
              Todo allí es modestísimo; sólo hay derroche de luz que penetra a
              raudales por las numerosas ventanas. Diríase, al contemplarlo, que
              todo el esfuerzos de los protestantes se ha encaminado a inundar
              de claridad sus templos, como pretenden hacer con las almas. He
              aquí un símbolo muy digno de tenerse en cuenta. Esta capilla
              estuvo situada anteriormente en la glorieta de Quevedo, esquina a
              la calle de Arapiles, donde después de alzó el estudio de
              Benlliure.
            </p>
            <div className="flex flex-col sm:flex-row-reverse">
              <div className="mb-8 text-center sm:flex-1 sm:pl-4">
                <StaticImage
                  alt="Retrato de Thomas W. Rhodes."
                  src="../images/thomas-w-rhodes.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Retrato de Thomas W. Rhodes.
                </figcaption>
              </div>
              <div className="sm:flex-1">
                <p className="mb-8">
                  Diez años más tarde, Faithfull regresó a su patria terrenal y,
                  en 1907, le sucedió el misionero{' '}
                  <strong>Thomas W. Rhodes</strong> (fallecido en 1940), recién
                  casado con <strong>Amelia Plummer</strong> (1867–1953). En
                  aquellos tiempos la congregación en la calle de Trafalgar no
                  pasaba de un centenar de miembros, pero los señores Rhodes
                  tenían la responsabilidad de las escuelas diarias que se
                  hallaban entonces una a cada lado de la capilla. Después de
                  algunos años se interesaron por la gran barriada de Tetuán de
                  las Victorias al norte de Madrid (hoy Barrio de Tetuán), y por
                  fin hicieron su residencia allí en la calle del Marqués de
                  Viana. Su interés misionero se extendió al pueblo de Chinchón
                  y en diversos puntos del Valle del Tiétar (Ávila), donde se
                  inició la obra en Piedralaves y Sotillo. Así pues, los Rhodes
                  pastorearon la asamblea en calle de Trafalgar durante más de
                  30 años.
                </p>
              </div>
            </div>
            <p className="mb-8">
              A partir de 1920, vinieron a colaborar nuevos pioneros de Dios:{' '}
              <strong>Arthur S. Chappell</strong> y{' '}
              <strong>Ernest H. Trenchard</strong> (1902–1972). El primero llegó
              en 1926 a la capital de España, tras seis años de duro trabajo en
              Orense; Trenchard estaba ya en Madrid, ocupándose del grupo de
              jóvenes e intentando interesarles en un estudio más completo de la
              Biblia. En 1929, ambos jóvenes misioneros fundaron la revista “El
              Joven Cristiano” que se publicó desde 1929 a 1936. En sus 20
              páginas mensuales —que se vendía a 25 céntimos el ejemplar—
              aparecieron no sólo buenos estudios bíblicos de Edmund G. Woodford
              o Thomas W. Rhodes, sino las primeras poesías de Mariano San León
              Herreras y la prosa de Santos García Retuerto, Gabriel Sánchez,
              Sagrario Bartolí o Audelino González Villa.
            </p>
            <div className="flex flex-col sm:flex-row">
              <div className="mb-8 text-center sm:flex-1 sm:pr-4">
                <StaticImage
                  alt="Retrato de Ernest H. Trenchard."
                  src="../images/ernest-h-trenchard.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Retrato de Ernest H. Trenchard.
                </figcaption>
              </div>
              <div className="sm:flex-1">
                <p className="mb-8">
                  El misionero Ernest H. Trenchard, junto a su esposa Gertrudis
                  Wille, trabajó en Málaga, Arenas de San Pedro y Toledo. Luego,
                  en 1945, desde la ciudad de Barcelona, comenzó una labor
                  intensiva de enseñanza y formación bíblicas, por lo que es
                  mayormente recordado. El buen número de alumnos, que
                  reclamaban formación bíblica, lo movió a crear una pequeña
                  escuela bíblica (CEB, Cursos de Estudio Bíblico, hoy CEFB),
                  una editorial (LB, Literatura Bíblica) y un equipo de
                  enseñadores compuesto tanto por misioneros extranjeros como
                  españoles. En 1964 se trasladó a Madrid para poder servir
                  mejor a las iglesias de todo el país. Trenchard ejerció una
                  poderosa influencia en el protestantismo español,
                  especialmente en el campo de la exégesis y exposición bíblica,
                  ciertamente inaugurado por él. Murió el 12 de abril de 1972.
                  El teólogo y filósofo Manuel Gutiérrez Marín, dijo de él:
                </p>
              </div>
            </div>
            <p className="ml-10 mb-8">
              Era un teólogo “carismático” de verdad, un varón movido por el
              Espíritu Santo, un hombre para el cual el texto bíblico era
              verdadera “Palabra de Dios”. Y justamente por eso no confiaba en
              su propio y muy despierto espíritu.
            </p>
            <p className="mb-8">
              De la Iglesia Evangélica de Chamberí salieron tres evangelistas
              españoles, dedicados a pleno tiempo: en 1922, Cecilio Fernández
              (primero en el valle del Tietar y luego en Galicia); en 1927,
              Francisco Fernández García (colaborador de Trenchard en
              Piedralaves y Arenas de San Pedro); y en 1933, Gabriel Sánchez
              quien sería asesinado en Navarres de Enmedio (Segovia) por unos
              nacionalistas católicos, en julio de 1936.
            </p>
            <p className="mb-8">
              El periodo de 1936 a 1964 tiene mal principio: la Guerra Civil.
              Para el testimonio evangélico, más que el principio de algo, fue
              el fin histórico de muchas cosas que eran, o se presumían,
              positivas y favorables.
            </p>
            <p className="mb-8">
              Las Escuelas evangélicas sintieron el efecto de la guerra, y de la
              post-guerra. Las Iglesias constituidas, necesitadas como minoría
              de inter-comunión, quedaron separadas geográficamente por la
              divisoria del frente bélico y las dificultades para los viajes y
              la comunicación en general. Las movilizaciones por la guerra no
              sólo disminuyeron la membresía de las Asambleas, sino que
              contribuyeron a la dispersión de familias que tenían que buscar
              refugio y ayuda en otras localidades.
            </p>
            <p className="mb-8">
              Los misioneros que trabajaban desde hace muchos años y sobre los
              que —en algunas iglesias— recaía principalmente la enseñanza y el
              pastoreo, tuvieron que regresar a sus lugares de origen; ausencia
              que en algunos casos se prolongó por los efectos de la II Guerra
              Mundial.
            </p>
            <p className="mb-8">
              También sufrió grandemente la difusión de la Biblia, muy activa
              durante la etapa anterior, principalmente durante la Segunda
              República, mediante los Coches Bíblicos y los establecimientos
              públicos. De igual manera, campañas de evangelización en teatros,
              y la celebración de Congresos Evangélicos, de afirmación y
              testimonio público, disminuyeron hasta extinguirse debido al
              ambiente de guerra que se hacía sentir más a medida que avanzaba
              el conflicto.
            </p>
            <p className="mb-8">
              No obstante, en el campo evangélico, hubo factores positivos como
              el espíritu de solidaridad y ayuda despertado entre los
              evangélicos de países extranjeros, hecho patente en la
              organización y sostén de colonias para alejar a los niños de los
              horrores y privacidad de la guerra, así como en la ayuda en
              alimentos.
            </p>
            <h2 className="mb-10 text-2xl font-bold text-gray-800 md:text-4xl">
              La actual capilla en calle de Trafalgar 32
            </h2>
            <p className="mb-8">
              La postguerra fue un largo periodo durante el cual el testimonio
              evangélico, en general, se reavivó a pesar de la intransigente
              hostilidad de la Iglesia Católica y del ostracismo civil y
              administrativo del nuevo Estado surgido de la contienda.
            </p>
            <p className="mb-8">
              Así pues, al fallecer Thomas W. Rhodes, en 1940 ocupó el pastorado{' '}
              <strong>John H. H. Biffen</strong> (1893–1960) quien vino a Madrid
              junto con su esposa Margarita desde Gijón donde habían permanecido
              desde 1921 a 1937.
            </p>
            <div className="flex flex-col sm:flex-row-reverse">
              <div className="mb-8 text-center sm:flex-1 sm:pl-4">
                <StaticImage
                  alt="Retrato de John H. H. Biffen."
                  src="../images/john-h-h-biffen.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Retrato de John H. H. Biffen.
                </figcaption>
              </div>
              <div className="sm:flex-1">
                <p className="mb-8">
                  La antigua capilla en calle Trafalgar estaba en peligro de
                  derrumbarse, y con el apoyo moral y la ayuda económica de
                  Hermann Sautter, emprendió la imponente tarea de levantar el
                  edificio actual en la calle de Trafalgar 32, que consistiría
                  en un bloque de siete plantas destinado a viviendas de
                  alquiler que albergaría en su interior y en la planta baja —de
                  forma un tanto disimulada— el local de cultos que permanece
                  hasta la actualidad. Como hombre de fe que era, Biffen no
                  vaciló ante las grandes responsabilidades, ni ante las dudas
                  de bastantes hermanos, y es gracias a su esfuerzo y empeño que
                  la iglesia de la calle de Trafalgar debe su amplia y hermosa
                  capilla, inaugurada en noviembre del año 1947.
                </p>
              </div>
            </div>
            <p className="mb-8">
              Fue con motivo de la inauguración de la nueva capilla —y a partir
              de ella— que la Iglesia Evangélica de Chamberí convocaba, para
              celebrar el aniversario, a unas conferencias (llamadas “de
              Trafalgar”, o “de Madrid”) que pronto adquirieron un tácito rango
              de nacionales. Tal era la asistencia de hermanos de toda España, y
              tal la bondad y la oportunidad del ministerio de hermanos
              invitados para dirigir los mensajes y los estudios bíblicos. Una
              buena reunión llamada “de ancianos y obreros”, que se celebraba en
              estas ocasiones, facilitaba la intercomunicación de criterios ante
              las necesidades del momento y la visión del futuro, en las que no
              decayó la comunión, en un espíritu de consulta y orientación
              mutuas. Ello permitió, entre otras manifestaciones de comunión
              activa, las fructíferas campañas de “Juventud para Cristo”, y la
              reanudación de las actividades de la “Alianza Evangélica
              Española”.
            </p>
            <div className="mb-8 text-center">
              <figure>
                <StaticImage
                  alt="Inauguración de la actual capilla de Trafalgar en 1947."
                  src="../images/inauguracion-capilla-trafalgar.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Inauguración de la actual capilla de Trafalgar en 1947.
                </figcaption>
              </figure>
            </div>
            <p className="mb-8">
              Fue a partir de 1954 (la 8ª Conferencia Anual de Madrid) que esta
              se organizó conjuntamente por las asambleas de Trafalgar y Duque
              de Sesto a las que, en el devenir de los años, se fueron agregando
              las asambleas que han ido surgiendo procedentes de la iglesia en
              calle de Trafalgar: calle de Pinzón 16 (inicialmente en calle del
              Espinar 48, barrio de Carabanchel), calle de Ofelia Nieto 57 y
              calle de Tembleque 140 (barrio de Aluche).
            </p>
            <p className="mb-8">
              La última parte de su vida, John H. H. Biffen la ocupó
              constantemente en visitar y aconsejar aquellos grupos que se
              habían quedado sin obreros. Desde Cartagena hasta Huelva se
              interesó en la adquisición de capillas, animando a la vez a las
              iglesias con sus sanos consejos. No descuidó la obra en Madrid, y
              por sus muchas salidas los hermanos en Trafalgar aprendieron a
              desarrollar sus dones y sentir mayor responsabilidad propia. El
              Señor bendijo mucho la Obra en la capital entre jóvenes y mayores,
              y él quedará “siempre recordado por lo que ha hecho”, con la
              colaboración de todos los hermanos que le amaban.
            </p>
            <p className="mb-8">
              En su último viaje a su país descansó poco, dedicándose a abogar
              por varios fondos especiales para el adelanto de la Obra, y
              despertar interés en ella. Se sentía lleno de salud hasta seis
              semanas antes del fin de su carrera, y seguía incansable en su
              ministerio hasta que de repente se descubrió en su esófago el
              cáncer que dio fin a su vida antes de ser posible ninguna
              intervención quirúrgica.
            </p>
            <p className="mb-8">
              Su viuda cuenta un rasgo característico de él en el hospital. En
              una de las camas de la sala se hallaba un jovencito gravemente
              enfermo, y al ver a Biffen leer su Biblia le rogó encarecidamente
              que le enseñase a orar, ya que temía morir. Arrodillado al lado de
              su cama, él tuvo la satisfacción de llevar al joven a Cristo, y
              éste, a los pocos días, murió confiado y feliz: la última alma, de
              las muchas que le darán las gracias en las “moradas eternas” por
              su testimonio fiel. Finalmente, John H. H. Biffen pasó a la
              presencia del Señor en Londres, el 16 de junio de 1960.
            </p>
            <p className="mb-8">
              A partir del año 1953 (30 de mayo al 7 de junio) tuvieron lugar en
              Barcelona, con gran bendición, unas Conferencias, organizadas por
              las Asambleas de Barcelona, a la manera de las que se celebraban
              en Madrid, de periodicidad trianual, y ámbito nacional. Este
              periodo resultó fecundo gracias a esta comunión frecuente y amplia
              entre las Asambleas de España, de manera que crecieron en número
              los creyentes y las asambleas.
            </p>
            <p className="mb-8">
              A este periodo corresponde también la creación de obras colectivas
              —llamadas también <i>obras auxiliares</i>— con una mínima
              organización y estructura, pero con máximo responsabilidad y
              eficacia, como el Fondo de Evangelización (en 1949), casi desde el
              principio conocido por el acrónimo FONDEVÁN, que hasta la
              actualidad viene sirviendo de cauce distributivo para facilitar la
              ayuda a los siervos del Señor que dedican todo su tiempo a la
              Obra, en comunión con las Asambleas. O, el Fondo pro Locales
              (desde 1956) para ayudar a las asambleas en sus planes de reforma
              y mejora de locales. Hogares de Ancianos, Campamentos Juveniles,
              Cursos de Estudio Bíblico, por extensión y por correspondencia,
              han sido también creados desde los primeros años de este periodo
              (1936–1964) y sostenidos a travez de los años.
            </p>
            <div className="mb-8 text-center">
              <figure>
                <StaticImage
                  alt="Interior de la capilla de la calle de Trafalgar 32, Madrid, en el año 1950."
                  src="../images/interior-capilla-trafalgar.jpeg"
                  className="mb-3 rounded-md"
                />
                <figcaption className="text-sm text-gray-500">
                  Interior de la capilla de la calle de Trafalgar 32, Madrid, en
                  el año 1950.
                </figcaption>
              </figure>
            </div>
            <p className="mb-8">
              En el “Escudriñador Bíblico”, revista editada por los jóvenes de
              la iglesia en calle de Trafalgar (de fecha 16 de octubre de 1947),
              se encuentra una referencia a la inauguración de la nueva capilla.
              Pertenece a <strong>Fernando Cabrera Latorre</strong> (1875–1953),
              quien fue pastor de la iglesia en la calle de Beneficencia en
              Madrid, ubicación muy cercana a la calle de Trafalgar. En aquel
              entonces escribió lo siguiente:
            </p>
            <p className="ml-10 mb-8">
              ¿Podríamos decir muchas otras cosas acerca de esta iglesia? ¡Quién
              lo duda! Pero todo lo que pudiéramos decir son cosas que saben los
              que nos leen mucho mejor que nosotros, y por eso, antes que seguir
              molestando su atención, preferimos hacer aquí punto final. ¡Que la
              iglesia de Chamberí, una de las cuatro iglesias más antiguas de
              Madrid, siga siendo en el distrito de Chamberí como una luz puesta
              sobre un monte que nadie pueda apagar! Este es nuestro sincero
              deseo.
            </p>
            <p className="mb-8">
              Así fue fundada en Madrid la última de las Asambleas de Hermanos
              del siglo XIX. Sólo nos queda añadir, con profundo agradecimiento
              al Señor, lo que encontramos en las Escrituras: «Por tanto,
              nosotros también, teniendo en derredor nuestro tan grande nube de
              testigos, despojémonos de todo peso y del pecado que nos asedia, y
              corramos con paciencia la carrera que tenemos por delante, puestos
              los ojos en Jesús, el autor y consumador de la fe, el cual por el
              gozo puesto delante de él sufrió la cruz, menospreciando el
              oprobio, y se sentó a la diestra del trono de Dios.» (Hebreos
              12.1-2). «Acordaos de vuestros pastores, que os hablaron la
              palabra de Dios; considerad cuál haya sido el resultado de su
              conducta, e imitad su fe.» (Hebreos 13.7).
            </p>
            <h2 className="mb-10 text-2xl font-bold text-gray-800 md:text-4xl">
              Bibliografía
            </h2>
            <ul className="mb-10 ml-10 list-disc">
              <li className="mb-4">
                Revista “Edificación Cristiana”, enero de 1961.
              </li>
              <li className="mb-4">
                Revista “Edificación Cristiana”, noviembre-diciembre de
                1988/Núm. 130. Titulo: “125 años de testimonio bíblico en
                España: Asambleas Cristianas de Hermanos”. Se reproduce
                concretamente lo escrito por D. Pablo Enrique Le More y D. Juan
                Solé.
              </li>
              <li className="mb-4">
                “Roberto Chapman. Hermano y verdadero amigo de España.” Frank
                Holmes. (Editorial Literatura Bíblica, 1969).
              </li>
              <li className="mb-4">
                El “Escudriñador Bíblico” de fecha 16 de Octubre de 1947.
              </li>
            </ul>
          </section>
          <div className="item-center flex flex-col">
            <div>
              <StaticImage
                alt="La capilla y los colegios evangélicos de la calle Trafalgar 34, inaugurada en 1896 y que se mantuvo en pie hasta el año 1946."
                src="../images/capilla-colegios-evangelicos.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500">
                La capilla y los colegios evangélicos de la calle Trafalgar 34,
                inaugurada en 1896 y que se mantuvo en pie hasta el año 1946.
              </figcaption>
            </div>
            <div className="text-center">
              <StaticImage
                alt="Thomas W. Rhodes junto a su esposa, Amelia Plummer, probablemente en los años 1930."
                src="../images/thomas-rhodes-y-esposa.jpeg"
                className="mb-3 max-w-xl rounded-md"
              />
              <figcaption className="mb-8 text-sm text-gray-500">
                Thomas W. Rhodes junto a su esposa, Amelia Plummer,
                probablemente en los años 1930.
              </figcaption>
            </div>
            <div>
              <StaticImage
                alt="Ancianos y obreros en las conferencias de Madrid de 1956."
                src="../images/ancianos-y-obreros-1956.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500 md:max-w-3xl">
                Ancianos y obreros en las conferencias de Madrid de 1956.
                Segunda fila: Mariano San León Herreras (en el centro), John H.
                H. Biffen (primero de la derecha). Sentados: Manuel Campelo,
                Santiago Miñambres, Edmund G. Woodford, Federico Gray, Ernest H.
                Trenchard.
              </figcaption>
            </div>
            <div>
              <StaticImage
                alt="Ancianos y Obreros en las conferencias de Madrid, octubre de 1964."
                src="../images/ancianos-y-obreros-1964.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500">
                Ancianos y Obreros en las conferencias de Madrid, octubre de
                1964.
              </figcaption>
            </div>
            <div>
              <StaticImage
                alt="Ernest H. Trenchard junto a su esposa, Gertrudis Willie."
                src="../images/ernest-trenchard-y-esposa.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500">
                Ernest H. Trenchard junto a su esposa, Gertrudis Willie.
              </figcaption>
            </div>
            <div>
              <StaticImage
                alt="Culto de navidad."
                src="../images/culto-de-navidad.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500">
                Culto de navidad.
              </figcaption>
            </div>
            <div>
              <StaticImage
                alt="Reunión de mujeres."
                src="../images/reunion-de-mujeres.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500">
                Reunión de mujeres.
              </figcaption>
            </div>
            <div>
              <StaticImage
                alt="La hora de la merienda en la reunión de mujeres, lateral izquierdo de la capilla."
                src="../images/merienda-reunion-de-mujeres.jpeg"
                className="mb-3 max-w-3xl rounded-md"
              />
              <figcaption className="mb-8 text-center text-sm text-gray-500">
                La hora de la merienda en la reunión de mujeres, lateral
                izquierdo de la capilla.
              </figcaption>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head: HeadFC = () => <title>Historia</title>
export default HistoryPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
